.homeBackground {
    background-image: url('background.jpg');
}

@media only screen and (max-width: 600px) {
    .homeBackground {
        background-image: url('background-small.jpg');
    }
}

.homeScroll {
    margin-top: 500px;
}

@media only screen and (max-width: 600px) {
    .homeScroll {
        margin-top: 300px;
    }
}
