.cartLeftGrid {
    margin: 0 10px 10px 0;
}

.cartRightGrid {
    margin: 0;
}

@media only screen and (max-width: 900px) {
    .cartLeftGrid {
        margin: 0;
    }
    .cartRightGrid {
        margin: 10px 0 0 0;
    }
}
