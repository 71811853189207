// @import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;400;700&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
}

h1 {
  font-weight: 500;
}

p {
  padding-bottom: 10px;
}
// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
// }

.footer {
  background-color: #cccccc;
  border-top: solid 3px #444444;
//  z-index: -1;  Combination of these two disables the links in the footer
//  position: relative;
}

.mainBox {
  box-shadow: 5px 5px 10px 0px rgb(0 0 0 / 50%);
  padding: 10px 20px 40px 20px;
  display: flex;
  justify-content: center;
  z-index: 12;
}

@media only screen and (max-width: 600px) {
  .mainBox {
    padding: 0 10px 30px 10px;
  }
}


.subMainBox {
  max-width: 1280px;
  width: 100%;
}

// Grey the screen
.inProgress {
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 20;
}

// Show a progress spinner
.spinner {
  border-radius: 50% !important;
  background: #232363;
  background: linear-gradient(to right, #232363 10%, rgba(0, 208, 228, 0) 42%) !important;
  animation: loadSpinner 2s infinite linear;
  transform: translateZ(0);
  width: 70px !important;
  height: 70px !important;
  // margin: 2px !important;
}

.spinner::before {
  width: 50%;
  height: 50%;
  background: #232363;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.spinner::after {
  background: #ffffff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes loadSpinner {
  0% {
      transform: rotate(0);
  }

  100% {
      transform: rotate(360deg);
  }
}

@keyframes loadSpinner {
  0% {
      transform: rotate(0);
  }

  100% {
      transform: rotate(360deg);
  }
}

